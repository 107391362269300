exports.components = {
  "component---src-data-pages-404-index-jsx": () => import("./../../../src/data/pages/404/index.jsx" /* webpackChunkName: "component---src-data-pages-404-index-jsx" */),
  "component---src-data-pages-behandlung-eines-reizdarms-index-jsx": () => import("./../../../src/data/pages/behandlung-eines-reizdarms/index.jsx" /* webpackChunkName: "component---src-data-pages-behandlung-eines-reizdarms-index-jsx" */),
  "component---src-data-pages-chronischer-durchfall-index-jsx": () => import("./../../../src/data/pages/chronischer-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-chronischer-durchfall-index-jsx" */),
  "component---src-data-pages-content-behandlung-eines-reizdarms-index-jsx": () => import("./../../../src/data/pages/content/behandlung-eines-reizdarms/index.jsx" /* webpackChunkName: "component---src-data-pages-content-behandlung-eines-reizdarms-index-jsx" */),
  "component---src-data-pages-content-brauche-ich-f-c-3-b-cr-imodium-c-2-ae-ein-rezept-index-jsx": () => import("./../../../src/data/pages/content/brauche-ich-f%C3%BCr-imodium%C2%AE-ein-rezept/index.jsx" /* webpackChunkName: "component---src-data-pages-content-brauche-ich-f-c-3-b-cr-imodium-c-2-ae-ein-rezept-index-jsx" */),
  "component---src-data-pages-content-brauche-ich-fur-imodium-ein-rezept-index-jsx": () => import("./../../../src/data/pages/content/brauche-ich-für-imodium-ein-rezept/index.jsx" /* webpackChunkName: "component---src-data-pages-content-brauche-ich-fur-imodium-ein-rezept-index-jsx" */),
  "component---src-data-pages-content-cookie-richtlinien-index-jsx": () => import("./../../../src/data/pages/content/cookie-richtlinien/index.jsx" /* webpackChunkName: "component---src-data-pages-content-cookie-richtlinien-index-jsx" */),
  "component---src-data-pages-content-datenschutz-index-jsx": () => import("./../../../src/data/pages/content/datenschutz/index.jsx" /* webpackChunkName: "component---src-data-pages-content-datenschutz-index-jsx" */),
  "component---src-data-pages-content-durchfall-bei-kindern-index-jsx": () => import("./../../../src/data/pages/content/durchfall-bei-kindern/index.jsx" /* webpackChunkName: "component---src-data-pages-content-durchfall-bei-kindern-index-jsx" */),
  "component---src-data-pages-content-durchfall-wahrend-ihrer-periode-index-jsx": () => import("./../../../src/data/pages/content/durchfall-wahrend-ihrer-periode/index.jsx" /* webpackChunkName: "component---src-data-pages-content-durchfall-wahrend-ihrer-periode-index-jsx" */),
  "component---src-data-pages-content-durchfall-was-tun-index-jsx": () => import("./../../../src/data/pages/content/durchfall-was-tun/index.jsx" /* webpackChunkName: "component---src-data-pages-content-durchfall-was-tun-index-jsx" */),
  "component---src-data-pages-content-es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun-index-jsx": () => import("./../../../src/data/pages/content/es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun/index.jsx" /* webpackChunkName: "component---src-data-pages-content-es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun-index-jsx" */),
  "component---src-data-pages-content-expertenrat-index-jsx": () => import("./../../../src/data/pages/content/expertenrat/index.jsx" /* webpackChunkName: "component---src-data-pages-content-expertenrat-index-jsx" */),
  "component---src-data-pages-content-fakten-uber-durchfall-index-jsx": () => import("./../../../src/data/pages/content/fakten-uber-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-fakten-uber-durchfall-index-jsx" */),
  "component---src-data-pages-content-faq-page-index-jsx": () => import("./../../../src/data/pages/content/faq-page/index.jsx" /* webpackChunkName: "component---src-data-pages-content-faq-page-index-jsx" */),
  "component---src-data-pages-content-glossar-index-jsx": () => import("./../../../src/data/pages/content/glossar/index.jsx" /* webpackChunkName: "component---src-data-pages-content-glossar-index-jsx" */),
  "component---src-data-pages-content-imodium-akut-schmelztabletten-index-jsx": () => import("./../../../src/data/pages/content/imodium-akut-schmelztabletten/index.jsx" /* webpackChunkName: "component---src-data-pages-content-imodium-akut-schmelztabletten-index-jsx" */),
  "component---src-data-pages-content-imodium-kapseln-index-jsx": () => import("./../../../src/data/pages/content/imodium-kapseln/index.jsx" /* webpackChunkName: "component---src-data-pages-content-imodium-kapseln-index-jsx" */),
  "component---src-data-pages-content-imodium-produkte-index-jsx": () => import("./../../../src/data/pages/content/imodium-produkte/index.jsx" /* webpackChunkName: "component---src-data-pages-content-imodium-produkte-index-jsx" */),
  "component---src-data-pages-content-kann-ich-imodium-bei-reizdarm-anwenden-index-jsx": () => import("./../../../src/data/pages/content/kann-ich-imodium-bei-reizdarm-anwenden/index.jsx" /* webpackChunkName: "component---src-data-pages-content-kann-ich-imodium-bei-reizdarm-anwenden-index-jsx" */),
  "component---src-data-pages-content-kann-ich-imodium-verwenden-wenn-ich-schwanger-bin-oder-stille-index-jsx": () => import("./../../../src/data/pages/content/kann-ich-imodium-verwenden-wenn-ich-schwanger-bin-oder-stille/index.jsx" /* webpackChunkName: "component---src-data-pages-content-kann-ich-imodium-verwenden-wenn-ich-schwanger-bin-oder-stille-index-jsx" */),
  "component---src-data-pages-content-konnen-kinder-imodium-verwenden-index-jsx": () => import("./../../../src/data/pages/content/können-kinder-imodium-verwenden/index.jsx" /* webpackChunkName: "component---src-data-pages-content-konnen-kinder-imodium-verwenden-index-jsx" */),
  "component---src-data-pages-content-mein-magen-reagiert-manchmal-empfindlich-auf-stark-gewurzte-speisen-was-kann-ich-dagegen-tun-index-jsx": () => import("./../../../src/data/pages/content/mein-magen-reagiert-manchmal-empfindlich-auf-stark-gewürzte-speisen-was-kann-ich-dagegen-tun/index.jsx" /* webpackChunkName: "component---src-data-pages-content-mein-magen-reagiert-manchmal-empfindlich-auf-stark-gewurzte-speisen-was-kann-ich-dagegen-tun-index-jsx" */),
  "component---src-data-pages-content-nahrungsmittelallergie-index-jsx": () => import("./../../../src/data/pages/content/nahrungsmittelallergie/index.jsx" /* webpackChunkName: "component---src-data-pages-content-nahrungsmittelallergie-index-jsx" */),
  "component---src-data-pages-content-reisedurchfall-vorbeugen-index-jsx": () => import("./../../../src/data/pages/content/reisedurchfall-vorbeugen/index.jsx" /* webpackChunkName: "component---src-data-pages-content-reisedurchfall-vorbeugen-index-jsx" */),
  "component---src-data-pages-content-richtig-essen-index-jsx": () => import("./../../../src/data/pages/content/richtig-essen/index.jsx" /* webpackChunkName: "component---src-data-pages-content-richtig-essen-index-jsx" */),
  "component---src-data-pages-content-search-index-jsx": () => import("./../../../src/data/pages/content/search/index.jsx" /* webpackChunkName: "component---src-data-pages-content-search-index-jsx" */),
  "component---src-data-pages-content-stress-durchfall-index-jsx": () => import("./../../../src/data/pages/content/stress-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-stress-durchfall-index-jsx" */),
  "component---src-data-pages-content-ursachen-von-durchfall-index-jsx": () => import("./../../../src/data/pages/content/ursachen-von-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-ursachen-von-durchfall-index-jsx" */),
  "component---src-data-pages-content-wann-stellt-sich-nach-dem-durchfall-der-normale-stuhlgang-wieder-ein-index-jsx": () => import("./../../../src/data/pages/content/wann-stellt-sich-nach-dem-durchfall-der-normale-stuhlgang-wieder-ein/index.jsx" /* webpackChunkName: "component---src-data-pages-content-wann-stellt-sich-nach-dem-durchfall-der-normale-stuhlgang-wieder-ein-index-jsx" */),
  "component---src-data-pages-content-warum-bekomme-ich-haufig-durchfall-wahrend-meiner-periode-index-jsx": () => import("./../../../src/data/pages/content/warum-bekomme-ich-häufig-durchfall-während-meiner-periode/index.jsx" /* webpackChunkName: "component---src-data-pages-content-warum-bekomme-ich-haufig-durchfall-wahrend-meiner-periode-index-jsx" */),
  "component---src-data-pages-content-warum-bekomme-ich-immer-durchfall-wenn-ich-grosse-mengen-esse-und-trinke-index-jsx": () => import("./../../../src/data/pages/content/warum-bekomme-ich-immer-durchfall-wenn-ich-große-mengen-esse-und-trinke/index.jsx" /* webpackChunkName: "component---src-data-pages-content-warum-bekomme-ich-immer-durchfall-wenn-ich-grosse-mengen-esse-und-trinke-index-jsx" */),
  "component---src-data-pages-content-warum-leide-ich-im-urlaub-unter-durchfall-index-jsx": () => import("./../../../src/data/pages/content/warum-leide-ich-im-urlaub-unter-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-warum-leide-ich-im-urlaub-unter-durchfall-index-jsx" */),
  "component---src-data-pages-content-was-ist-durchfall-index-jsx": () => import("./../../../src/data/pages/content/was-ist-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-was-ist-durchfall-index-jsx" */),
  "component---src-data-pages-content-was-kann-ich-nehmen-wenn-ich-beim-besten-willen-keine-tabletten-schlucken-kann-index-jsx": () => import("./../../../src/data/pages/content/was-kann-ich-nehmen-wenn-ich-beim-besten-willen-keine-tabletten-schlucken-kann/index.jsx" /* webpackChunkName: "component---src-data-pages-content-was-kann-ich-nehmen-wenn-ich-beim-besten-willen-keine-tabletten-schlucken-kann-index-jsx" */),
  "component---src-data-pages-content-was-soll-ich-tun-wenn-ich-nicht-sicher-bin-was-meinen-wiederkehrenden-durchfall-auslost-index-jsx": () => import("./../../../src/data/pages/content/was-soll-ich-tun-wenn-ich-nicht-sicher-bin-was-meinen-wiederkehrenden-durchfall-auslöst/index.jsx" /* webpackChunkName: "component---src-data-pages-content-was-soll-ich-tun-wenn-ich-nicht-sicher-bin-was-meinen-wiederkehrenden-durchfall-auslost-index-jsx" */),
  "component---src-data-pages-content-was-tue-ich-wenn-meine-kinder-durchfall-bekommen-index-jsx": () => import("./../../../src/data/pages/content/was-tue-ich-wenn-meine-kinder-durchfall-bekommen/index.jsx" /* webpackChunkName: "component---src-data-pages-content-was-tue-ich-wenn-meine-kinder-durchfall-bekommen-index-jsx" */),
  "component---src-data-pages-content-was-verursacht-akuten-kurz-andauernden-durchfall-index-jsx": () => import("./../../../src/data/pages/content/was-verursacht-akuten-kurz-andauernden-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-was-verursacht-akuten-kurz-andauernden-durchfall-index-jsx" */),
  "component---src-data-pages-content-wenn-ich-einen-vortrag-halten-muss-werde-ich-so-nervos-dass-sich-mir-der-magen-umdreht-warum-index-jsx": () => import("./../../../src/data/pages/content/wenn-ich-einen-vortrag-halten-muss-werde-ich-so-nervös-dass-sich-mir-der-magen-umdreht-warum/index.jsx" /* webpackChunkName: "component---src-data-pages-content-wenn-ich-einen-vortrag-halten-muss-werde-ich-so-nervos-dass-sich-mir-der-magen-umdreht-warum-index-jsx" */),
  "component---src-data-pages-content-wirken-orale-rehydrierungslosungen-gegen-durchfall-index-jsx": () => import("./../../../src/data/pages/content/wirken-orale-rehydrierungslösungen-gegen-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-content-wirken-orale-rehydrierungslosungen-gegen-durchfall-index-jsx" */),
  "component---src-data-pages-cookie-richtlinien-index-jsx": () => import("./../../../src/data/pages/cookie-richtlinien/index.jsx" /* webpackChunkName: "component---src-data-pages-cookie-richtlinien-index-jsx" */),
  "component---src-data-pages-datenschutz-index-jsx": () => import("./../../../src/data/pages/datenschutz/index.jsx" /* webpackChunkName: "component---src-data-pages-datenschutz-index-jsx" */),
  "component---src-data-pages-diagnose-reizdarm-index-jsx": () => import("./../../../src/data/pages/diagnose-reizdarm/index.jsx" /* webpackChunkName: "component---src-data-pages-diagnose-reizdarm-index-jsx" */),
  "component---src-data-pages-durchfall-bei-kindern-index-jsx": () => import("./../../../src/data/pages/durchfall-bei-kindern/index.jsx" /* webpackChunkName: "component---src-data-pages-durchfall-bei-kindern-index-jsx" */),
  "component---src-data-pages-durchfall-wahrend-ihrer-periode-index-jsx": () => import("./../../../src/data/pages/durchfall-wahrend-ihrer-periode/index.jsx" /* webpackChunkName: "component---src-data-pages-durchfall-wahrend-ihrer-periode-index-jsx" */),
  "component---src-data-pages-expertenrat-index-jsx": () => import("./../../../src/data/pages/expertenrat/index.jsx" /* webpackChunkName: "component---src-data-pages-expertenrat-index-jsx" */),
  "component---src-data-pages-fakten-uber-durchfall-index-jsx": () => import("./../../../src/data/pages/fakten-uber-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-fakten-uber-durchfall-index-jsx" */),
  "component---src-data-pages-faq-page-index-jsx": () => import("./../../../src/data/pages/faq-page/index.jsx" /* webpackChunkName: "component---src-data-pages-faq-page-index-jsx" */),
  "component---src-data-pages-gastroenteritis-index-jsx": () => import("./../../../src/data/pages/gastroenteritis/index.jsx" /* webpackChunkName: "component---src-data-pages-gastroenteritis-index-jsx" */),
  "component---src-data-pages-glossar-index-jsx": () => import("./../../../src/data/pages/glossar/index.jsx" /* webpackChunkName: "component---src-data-pages-glossar-index-jsx" */),
  "component---src-data-pages-hohe-arbeitsbelastung-index-jsx": () => import("./../../../src/data/pages/hohe-arbeitsbelastung/index.jsx" /* webpackChunkName: "component---src-data-pages-hohe-arbeitsbelastung-index-jsx" */),
  "component---src-data-pages-imodium-akut-schmelztabletten-index-jsx": () => import("./../../../src/data/pages/imodium-akut-schmelztabletten/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-akut-schmelztabletten-index-jsx" */),
  "component---src-data-pages-imodium-kapseln-index-jsx": () => import("./../../../src/data/pages/imodium-kapseln/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-kapseln-index-jsx" */),
  "component---src-data-pages-imodium-produkte-index-jsx": () => import("./../../../src/data/pages/imodium-produkte/index.jsx" /* webpackChunkName: "component---src-data-pages-imodium-produkte-index-jsx" */),
  "component---src-data-pages-impressum-index-jsx": () => import("./../../../src/data/pages/impressum/index.jsx" /* webpackChunkName: "component---src-data-pages-impressum-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-infomaterial-index-jsx": () => import("./../../../src/data/pages/infomaterial/index.jsx" /* webpackChunkName: "component---src-data-pages-infomaterial-index-jsx" */),
  "component---src-data-pages-kontakt-index-jsx": () => import("./../../../src/data/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-data-pages-kontakt-index-jsx" */),
  "component---src-data-pages-lebensmittelvergiftung-index-jsx": () => import("./../../../src/data/pages/lebensmittelvergiftung/index.jsx" /* webpackChunkName: "component---src-data-pages-lebensmittelvergiftung-index-jsx" */),
  "component---src-data-pages-loperamid-index-jsx": () => import("./../../../src/data/pages/loperamid/index.jsx" /* webpackChunkName: "component---src-data-pages-loperamid-index-jsx" */),
  "component---src-data-pages-nahrungsmittelallergie-index-jsx": () => import("./../../../src/data/pages/nahrungsmittelallergie/index.jsx" /* webpackChunkName: "component---src-data-pages-nahrungsmittelallergie-index-jsx" */),
  "component---src-data-pages-norovirus-index-jsx": () => import("./../../../src/data/pages/norovirus/index.jsx" /* webpackChunkName: "component---src-data-pages-norovirus-index-jsx" */),
  "component---src-data-pages-prufungssituationen-index-jsx": () => import("./../../../src/data/pages/prufungssituationen/index.jsx" /* webpackChunkName: "component---src-data-pages-prufungssituationen-index-jsx" */),
  "component---src-data-pages-rechtliche-hinweise-index-jsx": () => import("./../../../src/data/pages/rechtliche-hinweise/index.jsx" /* webpackChunkName: "component---src-data-pages-rechtliche-hinweise-index-jsx" */),
  "component---src-data-pages-reiseapotheke-index-jsx": () => import("./../../../src/data/pages/reiseapotheke/index.jsx" /* webpackChunkName: "component---src-data-pages-reiseapotheke-index-jsx" */),
  "component---src-data-pages-reisedurchfall-infos-und-fakten-index-jsx": () => import("./../../../src/data/pages/reisedurchfall-infos-und-fakten/index.jsx" /* webpackChunkName: "component---src-data-pages-reisedurchfall-infos-und-fakten-index-jsx" */),
  "component---src-data-pages-reisedurchfall-risikofaktoren-index-jsx": () => import("./../../../src/data/pages/reisedurchfall-risikofaktoren/index.jsx" /* webpackChunkName: "component---src-data-pages-reisedurchfall-risikofaktoren-index-jsx" */),
  "component---src-data-pages-reisedurchfall-vorbeugen-index-jsx": () => import("./../../../src/data/pages/reisedurchfall-vorbeugen/index.jsx" /* webpackChunkName: "component---src-data-pages-reisedurchfall-vorbeugen-index-jsx" */),
  "component---src-data-pages-reizdarm-ernahrung-index-jsx": () => import("./../../../src/data/pages/reizdarm-ernahrung/index.jsx" /* webpackChunkName: "component---src-data-pages-reizdarm-ernahrung-index-jsx" */),
  "component---src-data-pages-rezepte-index-jsx": () => import("./../../../src/data/pages/rezepte/index.jsx" /* webpackChunkName: "component---src-data-pages-rezepte-index-jsx" */),
  "component---src-data-pages-richtig-essen-index-jsx": () => import("./../../../src/data/pages/richtig-essen/index.jsx" /* webpackChunkName: "component---src-data-pages-richtig-essen-index-jsx" */),
  "component---src-data-pages-sites-default-files-checkliste-reiseapotheke-at-10-2018-index-jsx": () => import("./../../../src/data/pages/sites/default/files/Checkliste_Reiseapotheke_AT_10-2018/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-checkliste-reiseapotheke-at-10-2018-index-jsx" */),
  "component---src-data-pages-sites-default-files-datenschutz-index-jsx": () => import("./../../../src/data/pages/sites/default/files/datenschutz/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-datenschutz-index-jsx" */),
  "component---src-data-pages-sites-default-files-durchfall-wahrend-ihrer-periode-index-jsx": () => import("./../../../src/data/pages/sites/default/files/durchfall-wahrend-ihrer-periode/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-durchfall-wahrend-ihrer-periode-index-jsx" */),
  "component---src-data-pages-sites-default-files-expertenrat-index-jsx": () => import("./../../../src/data/pages/sites/default/files/expertenrat/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-expertenrat-index-jsx" */),
  "component---src-data-pages-sites-default-files-fakten-uber-durchfall-index-jsx": () => import("./../../../src/data/pages/sites/default/files/fakten-uber-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-fakten-uber-durchfall-index-jsx" */),
  "component---src-data-pages-sites-default-files-faq-page-index-jsx": () => import("./../../../src/data/pages/sites/default/files/faq-page/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-faq-page-index-jsx" */),
  "component---src-data-pages-sites-default-files-glossar-index-jsx": () => import("./../../../src/data/pages/sites/default/files/glossar/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-glossar-index-jsx" */),
  "component---src-data-pages-sites-default-files-imodium-akut-schmelztabletten-index-jsx": () => import("./../../../src/data/pages/sites/default/files/imodium-akut-schmelztabletten/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-imodium-akut-schmelztabletten-index-jsx" */),
  "component---src-data-pages-sites-default-files-richtig-essen-index-jsx": () => import("./../../../src/data/pages/sites/default/files/richtig-essen/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-richtig-essen-index-jsx" */),
  "component---src-data-pages-sites-default-files-search-index-jsx": () => import("./../../../src/data/pages/sites/default/files/search/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-default-files-search-index-jsx" */),
  "component---src-data-pages-stress-durchfall-index-jsx": () => import("./../../../src/data/pages/stress-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-stress-durchfall-index-jsx" */),
  "component---src-data-pages-tipps-bei-durchfall-index-jsx": () => import("./../../../src/data/pages/tipps-bei-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-tipps-bei-durchfall-index-jsx" */),
  "component---src-data-pages-tipps-fuer-den-winter-index-jsx": () => import("./../../../src/data/pages/tipps-fuer-den-winter/index.jsx" /* webpackChunkName: "component---src-data-pages-tipps-fuer-den-winter-index-jsx" */),
  "component---src-data-pages-ursachen-von-durchfall-index-jsx": () => import("./../../../src/data/pages/ursachen-von-durchfall/index.jsx" /* webpackChunkName: "component---src-data-pages-ursachen-von-durchfall-index-jsx" */),
  "component---src-data-pages-wie-wirkt-imodium-akut-index-jsx": () => import("./../../../src/data/pages/wie-wirkt-imodium-akut/index.jsx" /* webpackChunkName: "component---src-data-pages-wie-wirkt-imodium-akut-index-jsx" */)
}

